import React from 'react'

const Footer = () => (
  <div id="footer">
    <div className="inner">
    <ul className="copyright">
      <li> Points of Contact: </li>
    </ul>
      <ul className="icons">
        <li>
        <a href="mailto:ericwu.192@gmail.com?%20" className="icon fa-envelope-o">
          <span className="label">EMail</span>
        </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/in/ericwu-orie/" className="icon fa-linkedin">
            <span className="label">LinkedIn</span>
          </a>
        </li>
        <li>
          <a href="https://github.com/ew398" className="icon fa-github">
            <span className="label">GitHub</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
)

export default Footer
