import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/avatar.jpg'

const Header = () => (
  <header id="header">
    <div className="inner">
      <a href="#" className="image avatar">
        <img src={avatar} alt="" />
      </a>
      <h1>
        <strong>I am Eric, </strong><br />
         a data scientist <br />
         interested in creating <br />
         quantitative frameworks <br />
         to provide valuable solutions
      </h1>
    </div>
    <Footer />
  </header>
)

export default Header
